<template>
  <b-row no-gutters>
    <b-col cols="12" class="border-bottom">
      <h5 class="font-weight-bold p-2 mb-0 text-center bg-light">
        {{ subject }}
      </h5>
    </b-col>
    <b-col cols="12" class="p-2">
      <!-- INTRO -->
      <intro
        class="mb-2"
        :name="client.firstName"
        :text="
          $t('emails.clientOnlineCheckinRequest.alert', locale, {
            accommodationName: accommodationName,
          })
        "
      />

      <div class="d-flex justify-content-center mb-2">
        <b-img
          fluid
          class="online-checkin-illustration"
          src="https://media.foravilarentals.com/photos/emails/missing-online-checkin.jpeg"
        />
      </div>

      <!-- INTRANET BUTTON -->
      <b-button block variant="primary" :href="intranetLink" target="_blank">
        {{
          $t("emails.clientOnlineCheckinRequest.onlineCheckinButton", locale)
        }}
      </b-button>
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol, BButton, BImg } from "bootstrap-vue";
import Intro from "@/views/bookings/booking/components/mail/Intro.vue";
import {
  AGENCY_GUESTS_INTRANET_LOGIN,
  AGENCY_GUESTS_INTRANET_REGISTER,
} from "@appConfig";

export default {
  components: {
    BRow,
    BCol,
    BButton,
    Intro,
    BImg,
  },
  props: {
    locale: {
      type: String,
      default: "en-GB",
    },
  },
  data() {
    return {
      content: {},
    };
  },
  computed: {
    booking() {
      return this.$store.getters["booking/booking"];
    },
    client() {
      return this.$store.getters["booking/client"];
    },
    accommodation() {
      return this.$store.getters["accommodation/accommodation"];
    },
    accommodationName() {
      return this.accommodation?.name || null;
    },
    subject() {
      return `${this.$t(
        "emails.clientOnlineCheckinRequest.subject",
        this.locale,
        {
          localizator: this.booking?.localizator || null,
          accommodationName: this.accommodationName,
        }
      )}`;
    },
    intranetLink() {
      if (!this.client) return null;
      if (!this.client.user) {
        return `${AGENCY_GUESTS_INTRANET_REGISTER}/${this.client.uuid}?l=${this.locale}`;
      }
      return `${AGENCY_GUESTS_INTRANET_LOGIN}?l=${this.locale}`;
    },
  },
  watch: {
    content: {
      deep: true,
      handler() {
        this.$emit("content-updated", this.content);
      },
    },
  },
  created() {
    this.initContent();
  },
  methods: {
    initContent() {
      this.content = {
        locale: this.locale,
        subject: this.subject,
        clientName: this.client?.firstName || null,
        clientEmail: this.client?.email || null,
        accommodationName: this.accommodation?.name || null,
        localizator: this.booking?.localizator || null,
        intranetLink: this.intranetLink,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.email-content {
  margin: 0 auto;
  width: 640px;
}
.online-checkin-illustration {
  max-width: 300px;
}
</style>
